<template>
  <div>
    <title>SIM INVENTORY ~ ORDER LIST DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Order List Data
        <br />
        <h4>The following is a list of transaction data for the order list</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Filter data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Order Number"
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    @click="searchdata()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <div class="col-xs-12">
          <router-link to="/add-daftar-order">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Plus Data </i>
            </button>
          </router-link>
          <br /><br />
          <div class="box box-primary">
            <div class="box-header">
              <!-- <h3 class="box-title">Data Supplier</h3> -->
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Order Number</th>
                      <th>Buyer Code</th>
                      <th>Order Date</th>
                      <th>Export Date</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nomor_order }}</td>
                      <td>{{ classdata.kode_buyer }}</td>
                      <td>{{ classdata.tanggal_order }}</td>
                      <td>{{ classdata.tanggal_expor }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <router-link
                          :to="'/edit-daftar-order/' + classdata.id"
                        >
                          <button
                            title="Edit order list"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        &nbsp;
                        <router-link :to="'/add-order-detail/' + classdata.id">
                            <button title="Add order detail" class="btn btn-success">
                            <i class="fa fa-plus"></i>
                          </button>
                        </router-link> &nbsp;
                        <router-link :to="'/detail-order/' + classdata.id">
                            <button title="Detail order" class="btn btn-warning">
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link> &nbsp;
                        <button
                          title="Delete daftar order"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="nmsrc">
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
                <div v-else></div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 5,
      pageSizes: [5, 10, 25, 50],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.$router.push({ name: "daftarorder" });
        this.loading = true;
        this.fetchData();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_h/getalldatadaftar_order_h?cari=" + this.namesearch + "&length=" + this.pageSize;
        const urlAPIget = this.$apiurl + "daftar_order_h/getalldatadaftar_order_h?cari=" + this.namesearch + "&length=" + this.pageSize;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Peringatan",
              text: err.response.data.pesan,
            }).then(function (isConfirm) {
              if (isConfirm) {
                window.location.href = "/";
              }
            });
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Apakah yakin ?",
        text: "Data yang dipilih akan dihapus dan tidak bisa dikembalikan",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_h/hapusdaftar_order_h/" + id;
          const urlAPIbrgdel = this.$apiurl + "daftar_order_h/hapusdaftar_order_h/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Sukses",
                  text: "Hapus data berhasil",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Peringatan",
                  text: "Hapus data gagal",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Peringatan",
                text: err.response.data.pesan,
              }).then(function (isConfirm) {
                if (isConfirm) {
                  window.location.href = "/";
                }
              });
            });
        } else {
          this.loading = false;
        }
      });
    },

    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_h/getalldatadaftar_order_h?length=" + this.pageSize + "&page=" + this.page;
      const urlAPIget = this.$apiurl + "daftar_order_h/getalldatadaftar_order_h?length=" + this.pageSize + "&page=" + this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Peringatan",
            text: err.response.data.pesan,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>